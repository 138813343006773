export const quizResultsThemes = {
    covidGood: 'COVID_GOOD_RESULT',
    covidBad: 'COVID_BAD_RESULT',
    defaultGood: 'DEFAULT_GOOD_RESULT',
    defaultBad: 'DEFAULT_BAD_RESULT'
};

export const appointmentsStatus = {
    fullfilled: 'REALIZADO',
    scheduled: 'AGENDADO',
    waiting: 'AGUARDANDO_AGENDAMENTO'
}

export const appointmentTypes = {
    suggestion: 'sugestao',
    self: 'proprio',
    refer: 'encaminhado'
}

export const regexValidations = {
    cep: /\d{5}-\d{3}$/,
}


export const vaccinesStatus = {
    fullfilled: 'REALIZADO',
    scheduled: 'AGENDADO',
    waiting: 'AGUARDANDO_AGENDAMENTO'
}

export const vaccineTypes = {
    suggestion: 'sugestao',
    self: 'proprio',
    refer: 'encaminhado'
}

export const examStatus = {
    fullfilled: 'REALIZADO',
    scheduled: 'AGENDADO',
    waiting: 'AGUARDANDO_AGENDAMENTO'
}

export const examTypes = {
    suggestion: 'sugestao',
    self: 'proprio',
    refer: 'encaminhado'
}